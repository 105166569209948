import React from "react"
import { Link } from "gatsby"

import { graphql, useStaticQuery } from 'gatsby'

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import {Container, makeStyles} from "@material-ui/core";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import Image from "./image"
import Features from "./features"
import RandomPosts from "./randomposts"


// スタイルの記述をする
const useStyles = makeStyles({
  outer: {
    width: "100%",
    height: "100%",
    position: "absolute", 
    top: 0,
    left: 0
  },

  inner: {
    display: "grid",
    paddingLeft: "5vw",
    paddingRight: "5vw",
    alignItems: "center",
    textAlign: "center"
  },

  baseStyles: {
    position: 'relative',
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'repeat-y',
    backgroundSize: 'cover',
    margin: 0,
    padding: 0,
  },

  title: {
    color: "white",
    marginBottom: '13vh',
  },
  subTitle: {
    color: "white",
    margin: '7vw',
    marginBottom: '8vh'

  },

  phrase: {
    textAlign: 'center',
    paddingTop: 50,
    paddingBottom: 100,
    backgroundColor: "white"
  },
  card: {
    color: "gray",
    marginBottom: "10vh"
  }


});

const BackgroundSection = (props) => {
  const data = useStaticQuery(
    graphql`
      query {
        site: site(siteMetadata: {}) {
          siteMetadata {
            title
            description
            author
            catchphrase
          }
        }
      }
    `
  )

  const classes = useStyles();
  return (
    <div style = {{position: "relative", top: 0}}>
      <div
        style={{minHeight: props.breakpoints.sm ? "100vh" : "89vh"}}
      />
        <div className={classes.outer}>
          
          <div className={classes.inner} style={{minHeight: props.breakpoints.sm ? "90vh" : "87vh"}}>
            <div style={{maxWidth: props.breakpoints.sm ? "100%" : props.breakpoints.md ? "100%" : "100%"}}>
              <div
                data-sal="zoom-out"
                data-sal-duration="500" // changes duration of the animation (from 200 to 2000 ms)
                data-sal-delay="50" // adds delay to the animation (from 5 to 1000 ms)
                data-sal-easing="easeOutElastic" // 
              >
                <h1 className={classes.title} style={{fontSize: props.breakpoints.sm ? 32 : props.breakpoints.md ? 52 : 72}}>{data.site.siteMetadata.catchphrase}</h1>
              </div>
              <div
                data-sal="zoom-out"
                data-sal-duration="500" // changes duration of the animation (from 200 to 2000 ms)
                data-sal-delay="250" // adds delay to the animation (from 5 to 1000 ms)
                data-sal-easing="easeOutElastic" // 
              >
                <h2 className={classes.subTitle} style={{fontSize: props.breakpoints.sm ? 18 : props.breakpoints.md ? 22 : 28}}> {data.site.siteMetadata.description}</h2>
              </div>
              <div
                data-sal="zoom-out"
                data-sal-duration="500" // changes duration of the animation (from 200 to 2000 ms)
                data-sal-delay="500" // adds delay to the animation (from 5 to 1000 ms)
                data-sal-easing="easeOutElastic" // 
              >
                <span>
                  <Link
                    to="/contact/"
                    style={{textDecoration: "none"}}
                  ><Button style={{fontSize: props.breakpoints.md ? 22 : 28}} variant="contained" color="primary" endIcon={<ArrowForwardIcon />}>お問合せ</Button>
                  </Link>
                </span>
              </div> 
            </div>
          </div>
        </div>
    </div>
  )
}


const Top = (props) => {
  const classes = useStyles();
  return (
  <div>
    <div  style={{position: "fixed", top: 0, minWidth: "100%", height: "100vh", zIndex: -1}}><Image filename="inasaku_dark.jpeg" style={{height: "inherit"}}/></div>
    <BackgroundSection breakpoints = {props.breakpoints} style={{opacity: '0.8'}}/>
    <div className={classes.phrase} style = {{fontSize: props.breakpoints?.sm ? 24 : props.breakpoints.md ? 28 : 32}}>
    <RandomPosts a_number={4} breakpoints = {props.breakpoints}/>

    <Container fix>

    <div 
      data-sal="slide-up"
      data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
      data-sal-delay="100" // adds delay to the animation (from 5 to 1000 ms)
      data-sal-easing="ease" // 
      >
      <Card className={classes.card} variant="outlined">  
        <CardContent>
          <div style = {{ marginTop: "3vh", marginBottom: "2vh" ,fontSize: props.breakpoints?.sm ? 28 : props.breakpoints.md ? 32 : 36}}>
          「稲作支援SaaS」とは？
          </div>
          <div style = {{fontSize: props.breakpoints?.sm ? 16 : props.breakpoints.md ? 20 : 24}}>
            水田に農業向けITセンサーを設置し、米の品質向上・安定収穫を支援するシステムです。<br/>
            リアルタイムでの「気温・水位・水温・etc.」の計測・収集し、蓄積されたデータをもとにIBM Cloud上のAIの自動解析による作業提言・提案を行います。
          </div>
        </CardContent>
        <CardActions>
          <Link
            to="/overview/"
            style={{textDecoration: "none"}}>
              <Button endIcon={<ArrowForwardIcon />}>
                システム概要
              </Button>
          </Link>
        </CardActions>
      </Card>
    </div>
    </Container>
      <div 
        data-sal="slide-up"
        data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
        data-sal-delay="500" // adds delay to the animation (from 5 to 1000 ms)
        data-sal-easing="ease" // 
        style={{paddingLeft: "20vw", paddingRight: "20vw"}}>
        <Image filename="pc_mobile.png" />
      </div>
    
    </div>
    <Features breakpoints = {props.breakpoints}/>
  </div>
  )
}

export default Top
