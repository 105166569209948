import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Top from "../components/top"

const IndexPage = (props) => {
  const breakpoints = useBreakpoint();
  return (
  <Layout location={props.location} breakpoints = {breakpoints}>
    <Seo title="米作りの常識をITで変える" />
    <Top breakpoints = {breakpoints}/>

  </Layout>
  )
}

export default IndexPage
