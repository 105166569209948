import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Image from "./image"
import { Paper } from '@material-ui/core';
import { Container } from '@material-ui/core';

import Img from "gatsby-image"
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons' // 追加

const useStyles = makeStyles(() => ({

  root: {
    backgroundColor: "white"
  },
  problem: {
    paddingTop: "4vh",
    paddingBottom: "15vh"
  },
  grid: {
    paddingTop: "4vh",
    paddingBottom: "4vh",
  },
  gridImage: {
    width: "100%",
    height: "100%"
  },
  phrase: {
    color: "gray",
    textAlign: 'center',
    marginTop: 150,
    marginLeft: 30,
    marginBottom: 120,
    marginRight: 30,
  },
  feature: {
    backgroundColor: "ghostwhite",
    paddingTop: "10vh",
    paddingBottom: "15vh"
  },
  line: {
    paddingTop: "10vh",
    paddingBottom: "15vh"
  },
  line_inasaku: {
    margin: "5vw",

  }
}));

const SlideShow = ({images, interval, autoPlay, infiniteLoop}) => {
  return (
    <Carousel showThumbs={false} infiniteLoop={infiniteLoop} autoPlay={autoPlay} interval={interval}>
      {images.map(image => ( 
        <Img
          fluid={image.node.childImageSharp.fluid}
          key={image.node.id}
          alt={image.node.name.replace(/-/g, ' ').substring(2)}
          />
        ))}
    </Carousel>
  );
}

const Slides = () => {
  const { allFile } = useStaticQuery(
    graphql`
    query {
      allFile(
      sort: { fields: name, order: DESC }
      filter: { relativeDirectory: { eq: "slides" } }
      ) {
      edges {
        node {
        id
        name
        childImageSharp {
          fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
          }
        }
        }
      }
      }
    }
    `
  )
  return (
    <SlideShow images={allFile.edges} interval={10000} autoPlay={true} infiniteLoop={true} />
  )
}

const FeaturesMobile = (props) => {
  const classes = useStyles();
  const styleGridSubject = {margin: "3vw",fontSize: props.breakpoints?.sm ? 20 : 24}
  const styleGridDetail = {margin: "3vw",fontSize: props.breakpoints?.sm ? 14 : 18}
  const styleLineSubject = {margin: "3vw",fontSize: props.breakpoints?.sm ? 24 : 28}
  const styleLineDetail = {margin: "3vw",fontSize: props.breakpoints?.sm ? 16 : 20}
  return (
    <div>
      <div id="features" className={classes.feature}>
        <div className={classes.phrase} style = {{fontSize: props.breakpoints?.sm ? 28 : props.breakpoints.md ? 36 : 42}}>
          稲作支援SaaSができること
        </div>
        <Container fixed>
          <div                 
            data-sal="slide-up"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay="100" // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease" // 
            className={classes.grid}>
            <Paper  variant="outlined">
              <Grid container >
                <div className={classes.gridImage}>
                  <Image filename="dashboard.png" />
                </div>
                <div style={styleGridSubject}>
                  圃場情報を一目で確認
                </div>
                <div style = {styleGridDetail}>
                  ダッシュボードでは予想収穫日・生育状況・水位グラフなど、さまざまな情報を確認できます。
                </div>  
              </Grid>
            </Paper>
          </div>
          
          <div 
            data-sal="slide-up"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay="100" // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease" // 
            className={classes.grid}>
            <Paper  variant="outlined">
            <Grid container>
              <div className={classes.gridImage}>
                <Image filename="graph.png" />
              </div>
              <div style={styleGridSubject}>
                圃場毎のデータ収集・分析
              </div>
              <div style = {styleGridDetail}>
                圃場のセンサーから取得したデータを収集します。田植え時期からデータを分析することで今季の傾向を知ることができます。
              </div>
            </Grid>
            </Paper>
          </div>
          <div                 
            data-sal="slide-up"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay="100" // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease" // 
            className={classes.grid}>
            <Paper  variant="outlined">
            <Grid container>
              <div className={classes.gridImage}>
                <Image filename="growth.png" />
              </div>
              <div style={styleGridSubject}>
                生育記録の自動作成
              </div>
              <div style = {styleGridDetail} >
                自動で日々の画像を蓄積することで生育の記録が可能です。同時にAIの画像診断も行うことができます。
                例年の記録から学習することでナレッジとして蓄積できます。
              </div>
            </Grid>
            </Paper>
          </div>
        </Container>
      </div>
      <div className={classes.line}>
        <div className={classes.phrase} style = {{fontSize: props.breakpoints?.sm ? 28 : props.breakpoints.md ? 36 : 42}}>
        LINEとの連携
        </div>
        <Container fixed>
          <div className={classes.line_inasaku}><Image filename="line_inasaku.png" />  </div>
          <div                 
            data-sal="slide-up"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay="100" // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease" // 
            className={classes.grid}>
            
            <Paper variant="outlined">

              <div className={classes.gridImage}>
                <Slides />
              </div>
              <div style={{...styleLineSubject, textAlign: "center"}}>
                <strong>LINEで様々な操作が可能</strong>
              </div>
              <div style = {{...styleLineDetail, textAlign: "center"}}>
                定期報告・リスク発生時の警告通知<br/>
                圃場（センサー）情報の問い合わせ<br/>
                生育稲のAI画像診断<br/>
                日々の作業内容の入力<br/>
              </div>
            </Paper>
          </div> 
        </Container>
      </div>
    </div>
  )
}

const FeaturesPC = (props) => {
  const classes = useStyles();
  const styleGridSubject = {margin: "3vw",fontSize: props.breakpoints.md ? 24 : 28}
  const styleGridDetail = {margin: "3vw",fontSize: props.breakpoints.md ? 18 : 22}
  const styleLineSubject = {marginTop: 50,fontSize: props.breakpoints.md ? 24 : 28}
  const styleLineDetail = {marginTop: 50,fontSize: props.breakpoints.md ? 18 : 22}

  return (
    <div>
      <div id="features" className={classes.feature}>
        <div className={classes.phrase} style = {{fontSize: props.breakpoints?.sm ? 28 : props.breakpoints.md ? 36 : 42}}>
          稲作支援SaaSができること
        </div>
        <Container fixed>
          <div                 
            data-sal="slide-up"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay="100" // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease" // 
            className={classes.grid}>
            <Paper  variant="outlined">
              <Grid container >
                <Grid item xs={6}>
                  <div style={styleGridSubject}>
                    圃場情報を一目で確認
                  </div>
                  <div style = {styleGridDetail}>
                    ダッシュボードでは予想収穫日・生育状況・水位グラフなど、さまざまな情報を確認できます。
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div>
                    <Image filename="dashboard.png" />
                  </div>
                </Grid>
              </Grid>
            </Paper>
          </div>
          
          <div 
            data-sal="slide-up"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay="100" // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease" // 
            className={classes.grid}>
            <Paper  variant="outlined">
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <div>
                  <Image filename="graph.png" />
                </div>
              </Grid>
              <Grid item xs={6}>
                
                <div style={styleGridSubject}>
                  圃場毎のデータ収集・分析
                </div>
                <div style = {styleGridDetail}>
                  圃場のセンサーから取得したデータを収集します。田植え時期からデータを分析することで今季の傾向を知ることができます。
                </div>
                
              </Grid>
            </Grid>
            </Paper>
          </div>
          <div                 
            data-sal="slide-up"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay="100" // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease" // 
            className={classes.grid}>
            <Paper  variant="outlined">
            <Grid container spacing={10}>
              <Grid item xs={6}>
                <Container>
                  <div style={styleGridSubject}>
                    生育記録の自動作成
                  </div>
                  <div style = {styleGridDetail} >
                    自動で日々の画像を蓄積することで生育の記録が可能です。同時にAIの画像診断も行うことができます。
                    例年の記録から学習することでナレッジとして蓄積できます。
                  </div>
                </Container>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <Image filename="growth.png" />
                </div>
              </Grid>
            </Grid>
            </Paper>
          </div>
        </Container>
      </div>
      <div className={classes.line}>
        <div className={classes.phrase} style = {{fontSize: props.breakpoints?.sm ? 28 : props.breakpoints.md ? 36 : 42}}>
        LINEとの連携
        </div>
        <Container fixed>
          <div className={classes.line_inasaku}><Image filename="line_inasaku.png" />  </div>

          <div                 
            data-sal="slide-up"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay="100" // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease" // 
            className={classes.grid}>
            

            <Paper  variant="outlined">
              <Grid container>
                <Grid item xs={6}spacing={10}>
                  <div style={{...styleLineSubject, textAlign: "center"}}>
                    <strong>LINEで様々な操作が可能</strong>
                  </div>
                  <div style = {{...styleLineDetail, textAlign: "center"}}>
                    <div>定期報告・リスク発生時の警告通知</div>
                    <div>圃場（センサー）情報の問い合わせ</div>
                    <div>生育稲のAI画像診断</div>
                    <div>日々の作業内容の入力</div>
                  </div>
                </Grid>
                <Grid item xs={6}>
                    <Slides />
                </Grid>
              </Grid>

            </Paper>
          </div>
        </Container>
      </div>
    </div>
  )
}


const Features = (props) => {
  library.add(fab)
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <div className={classes.problem}>
        <div className={classes.phrase} style = {{fontSize: props.breakpoints?.sm ? 28 : props.breakpoints.md ? 36 : 42}}>
          こんなことで困っていませんか？
        </div>
        <Container fix>
          <div
            data-sal="slide-up"
            data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
            data-sal-delay="10" // adds delay to the animation (from 5 to 1000 ms)
            data-sal-easing="ease" // 
            style = {{fontSize: props.breakpoints?.sm ? 22 : props.breakpoints.md ? 24 : 28, paddingLeft: "5vw", paddingRight: "5vw"}}
          >
            <Paper variant="outlined">
              <p 
              data-sal="slide-up"
              data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
              data-sal-delay="200" // adds delay to the animation (from 5 to 1000 ms)
              data-sal-easing="ease" // 
              style = {{paddingLeft: "2vw", paddingRight: "2vw"}}>1日に何度も畑の様子をみにいっている</p>
              <p 
              data-sal="slide-up"
              data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
              data-sal-delay="400" // adds delay to the animation (from 5 to 1000 ms)
              data-sal-easing="ease" // 
              style = {{paddingLeft: "2vw", paddingRight: "2vw"}}>毎日、複数の圃場の見回りをしている</p>
              <p 
              data-sal="slide-up"
              data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
              data-sal-delay="600" // adds delay to the animation (from 5 to 1000 ms)
              data-sal-easing="ease" // 
              style = {{paddingLeft: "2vw", paddingRight: "2vw"}}>最適な作業時期がわからない・毎年同じ時期である</p>
            </Paper>
          </div>
        </Container>
      </div> 
      
      {
        props.breakpoints.pc ? null : <FeaturesMobile breakpoints= {props.breakpoints}/>
      }
      {
        props.breakpoints.mobile ?  null : <FeaturesPC breakpoints= {props.breakpoints}/>
      }

      
    </div>
  );
}
export default Features